

























































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.newContact {
  color: $colorPrimary;
  align-self: center;
  font-size: 16px;
  text-transform: uppercase;
}

.v-form {
  padding: 0px 16px 0px;
  border: 8px solid $colorPrimary;
  border-radius: 0px 0px 16px 16px !important;
}
